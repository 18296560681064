/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { QueryCache } from "@tanstack/react-query";
import toast from "react-hot-toast";

const apiURL = "https://api.mytsion.com";
// const apiURL = "https://stagingapi.mytsion.com";

const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error);
  },
  onSuccess: (data) => {
    console.log(data);
  },
});

async function client(
  endpoint: string,
  {
    data,
    headers: customHeaders,
    ...customConfig
  }: Partial<RequestInit> & { data?: unknown } = {}
) {
  const token = localStorage.getItem("token");
  const config: RequestInit = {
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": data ? "application/json" : "",
      ...customHeaders,
    },
    ...customConfig,
  };

  return await window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();

      if (response.status === 401) {
        queryCache.clear();
        // await auth.logout()
        // refresh the page for them
        toast.error(data?.message);
        // eslint-disable-next-line prefer-promise-reject-errors
        return await Promise.reject({ message: "Please re-authenticate." });
      }
      if (response.ok) {
        return data;
      } else {
        toast.error(data?.message);
        return await Promise.reject(data);
      }
    });
}

async function imageUploadClient(
  endpoint: string,
  {
    data,
    headers: customHeaders,
    method = data ? "POST" : "GET", // Default to POST if data is present
    ...customConfig
  }: Partial<RequestInit> & { data?: FormData | undefined } = {}
) {
  const token = localStorage.getItem("token");

  const config: RequestInit = {
    method,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      // Only include Content-Type if data is not FormData
      ...(data instanceof FormData
        ? {}
        : { "Content-Type": "application/json" }),
      ...customHeaders,
    },
    ...customConfig,
    body:
      method !== "GET" && method !== "HEAD" ? (data as BodyInit) : undefined, // Only attach body if method allows it
  };
  try {
    const response = await fetch(`${apiURL}/${endpoint}`, config);
    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else if (response.status === 401) {
      toast.error(responseData?.message || "Unauthorized access");
      throw new Error("Please re-authenticate.");
    } else {
      toast.error(responseData?.message || "An error occurred");
      throw new Error(responseData?.message || "Request failed");
    }
  } catch (error) {
    console.error("API request failed:", error);
    toast.error("An error occurred while processing the request");
    throw error;
  }
}

export { client, imageUploadClient };
