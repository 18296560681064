import React, { useEffect, useState } from "react";
import Layout from "layout";
import { useSetFee, useGetFee } from "utils/api/user.api";
import toast from "react-hot-toast";
import SwapIcon from "../../assets/images/swap.svg";
import WithdrawIcon from "../../assets/images/withdrawal.svg";
import BankCoin from "../../assets/icons/bankCoin.svg";

export default function RateSettings() {
  const [state, setState] = useState<any>({
    feeName: "",
    feePercentage: 0,
  });
  const [withdrawState, setWithdrawState] = useState<any>({
    feeName: "",
    feePercentage: 0,
  });
  const [buyMystionState, setBuyMystionState] = useState<any>({
    feeName: "",
    feePercentage: 0,
  });
  const { data } = useGetFee();
  const { mutate: setFee, isSuccess } = useSetFee();

  const getFee = (name: string) => {
    const response = data?.find((item: any) => item.feeName === name);
    if (response) {
      return response?.feePercentage;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Price updated successfully!");
    }
  }, [isSuccess]);

  return (
    <Layout>
      <div
        className="dashboard-main"
        style={{ height: "100vh", backgroundColor: " #FAFAFA" }}
      >
        <div className=" relative h-40 flex items-center bg-cyan-800 rounded-lg min-w-[300px]">
          <text
            style={{
              fontWeight: "500",
              fontSize: "32px",
              color: "white",
              padding: "24px",
            }}
          >
            Fee Management
          </text>
        </div>
        <div
          style={{
            display: "flex",
            gap: "32px",
            paddingInline: "50px",
            width: "100%",
          }}
          className="flex-col md:flex-row"
        >
          <div
            className={`bg-white rounded-lg flex flex-col w-full  p-5  gap-4 mt-10`}
          >
            <img src={SwapIcon} className="w-10 h-10" alt="Swap Icon" />

            {/* Second Row */}
            <div
              className={`bg-white rounded-lg flex items-center justify-between `}
            >
              <p className="text-lg  whitespace-nowrap font-bold basis-5/12 flex items-center gap-4">
                Swap Fee Percentage
              </p>
              <div className="basis-2/12 text-right">
                <span className="font-bold text-cyan-800 text-[16px]">
                  {getFee("swap_fee")}%
                </span>
              </div>
            </div>

            {/* Third Row */}
            <div
              className={`bg-white rounded-lg flex justify-between items-center block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 `}
            >
              <input
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value >= 0) {
                    // Check if the value is non-negative
                    setState({
                      feeName: "swap_fee",
                      feePercentage: value,
                    });
                  }
                }}
                value={state.feePercentage}
                type="number"
                placeholder="Enter new fee"
                className=" placeholder:text-gray-400 focus:outline-0 w-full "
              />
              <button
                onClick={() => {
                  setFee(state);
                  setState({
                    feeName: "",
                    feePercentage: 0,
                  });
                }}
                className="bg-cyan-800 border border-cyan-300 rounded-md text-white text-md transition px-8 py-2 sm:text-sm whitespace-nowrap"
              >
                UPDATE FEE
              </button>
            </div>
          </div>
          <div
            className={`bg-white rounded-lg flex w-full flex-col p-5 gap-4 mt-10`}
          >
            <img src={WithdrawIcon} className="w-10 h-10" alt="Withdraw Icon" />

            {/* Second Row */}
            <div className={` rounded-lg flex items-center justify-between`}>
              <p className="text-lg whitespace-nowrap font-bold basis-5/12 flex items-center gap-4">
                Withdraw Fiat Fee Percentage
              </p>
              <div className="basis-2/12 text-right">
                <span className="font-bold text-cyan-800 text-[16px]">
                  {getFee("withdraw_fiat_fee")}%
                </span>
              </div>
            </div>

            {/* Third Row */}
            <div
              className={`bg-white rounded-lg flex justify-between items-center block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 `}
            >
              <input
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value >= 0) {
                    // Check if the value is non-negative
                    setWithdrawState({
                      feeName: "withdraw_fiat_fee",
                      feePercentage: value,
                    });
                  }
                }}
                value={withdrawState.feePercentage}
                // disabled={isLoading}
                type="number"
                placeholder="Enter new fee"
                className=" placeholder:text-gray-400 focus:outline-0 w-full  "
              />
              <button
                onClick={() => {
                  setFee(withdrawState);
                  setWithdrawState({ feeName: "", feePercentage: 0 });
                }}
                className="bg-cyan-800 border border-cyan-300 rounded-md text-white text-md transition px-8 py-2 sm:text-sm whitespace-nowrap"
              >
                UPDATE FEE
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "32px",
            paddingInline: "50px",
            width: "100%",
          }}
          className="flex-col md:flex-row"
        >
          <div className="bg-white rounded-lg flex w-full flex-col p-5 gap-4 mt-10">
            <img src={BankCoin} className="w-10 h-10" alt="Mystion Icon" />
            <div className="rounded-lg flex items-center justify-between">
              <p className="text-lg whitespace-nowrap font-bold basis-5/12 flex items-center gap-4">
                Buy Mystion Fee Percentage
              </p>
              <div className="basis-2/12 text-right">
                <span className="font-bold text-cyan-800 text-[16px]">
                  {getFee("buy_mystion_fee")}%
                </span>
              </div>
            </div>
            <div
              className={`bg-white rounded-lg flex justify-between items-center block basis-2/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 `}
            >
              <input
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value >= 0) {
                    // Check if the value is non-negative
                    setBuyMystionState({
                      feeName: "buy_mystion_fee",
                      feePercentage: value,
                    });
                  }
                }}
                value={buyMystionState.feePercentage}
                type="number"
                placeholder="Enter new fee"
                className="placeholder:text-gray-400 focus:outline-0 w-full"
              />
              <button
                onClick={() => {
                  setFee(buyMystionState);
                  setBuyMystionState({ feeName: "", feePercentage: 0 });
                }}
                className="bg-cyan-800 border border-cyan-300 rounded-md text-white text-md transition px-8 py-2 sm:text-sm whitespace-nowrap"
              >
                UPDATE FEE
              </button>
            </div>
          </div>
          {/* <div className="w-1/2"></div> */}
        </div>
      </div>
    </Layout>
  );
}
