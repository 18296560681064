import AppStats from "components/appStats";
import Layout from "layout";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetTransactions } from "utils/api/user.api";
import { type UserTransaction } from "utils/types/user.type";
import searchIconSrc from "assets/icons/searchIcon.svg";
import { CSVLink } from "react-csv";
import XAFIcon from "assets/icons/XAF.png";

export default function CompletedTransactions() {
  const [searchValue, setSearchValue] = useState("");
  const { data: completedTrans, isLoading } = useGetTransactions("true");
  console.log("🚀 ~ CompletedTransactions ~ completedTrans:", completedTrans);
  return (
    <Layout>
      <div style={{ backgroundColor: " #FAFAFA" }} className="dashboard-main">
        <div>
          <div className=" relative h-40 bg-cyan-800 rounded-lg min-w-[300px]">
            <h1 className="font-medium text-2xl text-white p-8 ">
              Transaction History
            </h1>

            <div className="px-8">
              <AppStats />
            </div>
          </div>

          <div className="flex flex-col pt-6 lg:pt-24">
            <div className="flex flex-col md:flex-row gap-4 justify-end pb-8">
              <div className="relative w-full lg:w-96">
                <input
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search by date, email address or transaction type"
                  className="w-full text-grey-700 text-sm rounded-lg border border-grey-600 focus:outline-0 py-3.5 px-5 pr-10"
                />
                <img
                  src={searchIconSrc}
                  alt="Search"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-700 w-5 h-5"
                />
              </div>
              <button className="bg-cyan-300 border border-cyan-800 rounded-lg text-cyan-800 text-[18px] font-[500] transition px-8 lg:px-16 py-3">
                {completedTrans && (
                  <CSVLink
                    data={completedTrans}
                    style={{ textDecoration: "none", color: "#F7A600" }}
                    filename="pendingTransaction.csv"
                  >
                    EXPORT
                  </CSVLink>
                )}
              </button>
            </div>
            <div className="dashboard-sidebar table-wrapper max-h-[20rem] overflow-auto ">
              <table
                className="w-full border border-[#F1F1F1] "
                style={{ borderCollapse: "collapse", borderSpacing: "0" }}
              >
                <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-[-1px] z-10">
                  {" "}
                  <tr>
                    <th
                      style={{ width: "20%" }}
                      className="py-3 px-5 text-left"
                    >
                      Transaction
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="py-3 px-2 text-left"
                    >
                      Date
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="py-3 px-2 text-left"
                    >
                      Type
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="py-3 px-2 text-left"
                    >
                      Amount
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="py-3 px-2 text-left"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {completedTrans
                    ?.filter((item) => item.user?.email.includes(searchValue))
                    ?.map((transaction) => (
                      <TableRow
                        key={transaction?.id}
                        transaction={transaction}
                      />
                    ))}
                  {!isLoading &&
                    completedTrans?.filter(
                      (item) => item.user?.email.includes(searchValue)
                    )?.length === 0 && (
                      <tr>
                        <td>No Results Found</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ transaction }: { transaction: UserTransaction }) {
  console.log("🚀 ~ TableRow ~ transaction:", transaction);
  const [isEdit, setIsEdit] = useState(false);

  // Determine the row and text color based on the transaction type
  const getRowColor = (type: string) => {
    switch (type) {
      case "Withdraw Crypto":
        return "bg-[#E7FFF2]";
      case "Deposit Crypto":
        return "bg-[#FFE3E3]";
      case "Swap Crypto To Crypto":
        return "bg-[#F1F1F1]";
      case "Swap Fiat To Crypto":
        return "bg-[#F1F1F1]";
      case "Swap Crypto To Fiat":
        return "bg-[#F1F1F1]";
      default:
        return "bg-[#F1F1F1]";
    }
  };

  const getTextColor = (type: string) => {
    switch (type) {
      case "Withdraw Crypto":
        return "text-[#1B9E4B]";
      case "Deposit Crypto":
        return "text-[#EF4444]";
      case "Swap Crypto To Crypto":
        return "text-[#030319]";
      case "Swap Fiat To Crypto":
        return "text-[#030319]";
      case "Swap Crypto To Fiat":
        return "text-[#030319]";
      default:
        return "text-[#030319]";
    }
  };

  const renderDetails = () => {
    if (
      transaction?.type === "Deposit Fiat" ||
      transaction?.type === "Withdraw Fiat"
    ) {
      return (
        <>
          <td className="flex-1">
            <strong className="text-xs">Bank Name:</strong>
            <p className="text-black-900 text-xs">{transaction?.bankName}</p>
          </td>
          <td className="flex-1">
            <strong className="text-xs">Account Name:</strong>
            <p className="text-black-900 text-xs">{transaction?.accountName}</p>
          </td>
          <td className="flex-1">
            <strong className="text-xs">Amount:</strong>
            <p className="text-black-900 text-xs">{transaction?.amount}</p>
          </td>
          <td className="flex-1">
            <strong className="text-xs">Receipt Proof:</strong>
            <img
              src={transaction?.bankTrxUrl}
              alt="Receipt Proof"
              className="w-32"
            />
          </td>
        </>
      );
    }

    if (
      transaction?.type === "Withdraw" ||
      transaction?.type === "Deposit" ||
      transaction?.type === "Stake"
    ) {
      return (
        <>
          <td className="flex-1">
            <strong className="text-xs">Sender Address:</strong>
            <p className="text-black-900 text-xs">{transaction?.fromAddress}</p>
          </td>
          <td className="flex-1">
            <strong className="text-xs">Receiver Address:</strong>
            <p className="text-black-900 text-xs">{transaction?.toAddress}</p>
          </td>
          <td className="flex-1">
            <strong className="text-xs">Amount:</strong>
            <p className="text-black-900 text-xs">{transaction?.amount}</p>
          </td>
          <td className="flex-1">
            <strong className="text-xs">Token:</strong>
            <p className="text-black-900 text-xs">
              {transaction?.coin?.network?.name}
            </p>
          </td>
        </>
      );
    }

    return (
      <>
        <td className="flex-1">
          <strong className="text-xs">From Swap Token:</strong>
          <p className="text-black-900 text-xs">{transaction?.coin?.name}</p>
        </td>
        <td className="flex-1">
          <strong className="text-xs">To Swap Token:</strong>
          <p className="text-black-900 text-xs">{transaction?.toCoin?.name}</p>
        </td>
        <td className="flex-1">
          <strong className="text-xs">Amount:</strong>
          <p className="text-black-900 text-xs">{transaction?.amount}</p>
        </td>
        <td className="flex-1">
          <strong className="text-xs">Amount Swapped:</strong>
          <p className="text-black-900 text-xs">{transaction?.swappedAmount}</p>
        </td>
      </>
    );
  };

  // Determine the button class based on the isEdit state
  const buttonClass = isEdit
    ? "text-cyan-800 bg-cyan-300 border-cyan-800"
    : "text-gray-800  bg-[#F1F1F1]  border-[#8F92A1]";

  return (
    <>
      <tr className={`${getRowColor(transaction?.type)} transition`}>
        <td className="py-5 px-2">
          <p className="text-[#8F92A1] text-xs ">
            From {transaction?.user?.email}
          </p>
        </td>
        <td className="px-2 border-r-0 py-5">
          <p className="text-[#8F92A1] text-xs ml-3">
            {new Date(transaction?.createdAt)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .replace(" at", ",")}
          </p>
        </td>
        <td className="py-5 px-2">
          <p
            className={`${getTextColor(
              transaction?.type
            )} text-xs font-semibold`}
          >
            {transaction?.type}
          </p>
        </td>
        <td className="py-5 px-2">
          <p className=" text-sm  flex gap-1">
            {transaction?.type.includes("Withdraw Crypto") &&
              transaction?.currency?.symbol}
            <img
              src={transaction?.coin?.icon || XAFIcon}
              className="w-5"
              alt="icon"
            />{" "}
            {transaction?.amount}
          </p>
        </td>
        <td className="px-2 border-l-0 py-5">
          <button
            onClick={() => setIsEdit(!isEdit)}
            type="button"
            className={`text-xs font-medium  rounded-lg px-8 py-2.5 border ${buttonClass}`}
          >
            {isEdit ? "COLLAPSE" : "VIEW DETAIL"}
          </button>
        </td>
      </tr>
      {isEdit && (
        <tr className="relative w-full">
          <td colSpan={6} className="p-0 border-t-0 w-full">
            <table className="px-6 pb-6 w-full">
              <tbody className="w-full">
                <tr className="flex">
                  {renderDetails()}
                  <td className="flex-1">
                    <Link
                      to={`/users?search=${transaction?.user?.email}`}
                      className="text-black-900 text-xs underline leading-relaxed font-semibold"
                    >
                      CHECK USER
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
