import React, { useState, useEffect } from "react";
import { Modal } from "antd";

interface BankAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (data: BankAccountData) => void;
  initialData?: BankAccountData;
  currency: "usd" | "ngn";
}

interface BankAccountData {
  accountHolderName: string;
  accountNumber: string;
  bank: string;
  currency: "usd" | "ngn";
}

export function BankAccountModal({
  isOpen,
  onClose,
  onUpdate,
  initialData,
  currency,
}: BankAccountModalProps) {
  const [formData, setFormData] = useState<BankAccountData>({
    accountHolderName: "",
    accountNumber: "",
    bank: "",
    currency,
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        accountHolderName: initialData.accountHolderName || "",
        accountNumber: initialData.accountNumber || "",
        bank: initialData.bank || "",
        currency: initialData.currency || currency,
      });
    }
  }, [initialData, currency]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onUpdate({
      ...formData,
      currency,
    });
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={<h2 className="text-lg font-bold">Bank Account Detail</h2>}
      footer={null}
    >
      <div className="space-y-4 mt-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Account Name
          </label>
          <input
            type="text"
            name="accountHolderName"
            value={formData.accountHolderName}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Account Number
          </label>
          <input
            type="text"
            name="accountNumber"
            value={formData.accountNumber}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Bank
          </label>
          <input
            type="text"
            name="bank"
            value={formData.bank}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <button
          onClick={handleSubmit}
          className="w-full mt-4 py-2 px-4 bg-cyan-800 text-white rounded-md hover:bg-cyan-700"
        >
          UPDATE
        </button>
      </div>
    </Modal>
  );
}
