/* eslint-disable @typescript-eslint/no-unused-vars */
import AppStats from "components/appStats";
import Layout from "layout";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useGetTransactionsForApproval,
  useUpdateTransaction,
} from "utils/api/user.api";
import { type UserTransaction } from "utils/types/user.type";
import searchIconSrc from "assets/icons/searchIcon.svg";
import XAFIcon from "assets/icons/XAF.png";
import { CSVLink } from "react-csv";
export default function PendingTransactions() {
  const [searchValue, setSearchValue] = useState("");
  const { data: pendingTrans, isLoading } = useGetTransactionsForApproval();
  console.log("🚀 ~ PendingTransactions ~ pendingTrans:", pendingTrans);

  return (
    <Layout>
      <div style={{ backgroundColor: "#FAFAFA" }} className="dashboard-main">
        <div>
          <div className=" relative h-40 bg-cyan-800 rounded-lg min-w-[300px]">
            <h1 className="font-medium text-2xl text-white p-4 lg:p-8 ">
              Transaction Approval
            </h1>

            <div className="px-2 lg:px-10 ">
              <AppStats />
            </div>
          </div>

          <div className="flex flex-col pt-6 lg:pt-24">
            <div className="flex flex-col md:flex-row gap-4 justify-end pb-8">
              <div className="relative w-full lg:w-96">
                <input
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search by Date, Email or Transaction Type..."
                  className="w-full text-grey-700 font-Poppins text-sm rounded-lg border border-grey-600 focus:outline-0 py-3.5 px-5 pr-10"
                />
                <img
                  src={searchIconSrc}
                  alt="Search"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-grey-700 w-5 h-5"
                />
              </div>
              {/* <button className="bg-cyan-300 border border-cyan-800 rounded-lg text-cyan-800 text-base font-bold transition px-8 lg:px-16 py-3">
                EXPORT
              </button> */}
              <button className="bg-cyan-300 border border-cyan-800 rounded-lg text-[#0291D1] text-[18px] font-[500] transition px-8 lg:px-16 py-3">
                {pendingTrans && (
                  <CSVLink
                    data={pendingTrans}
                    style={{ textDecoration: "none", color: "#F7A600" }}
                    filename="pendingTransaction.csv"
                  >
                    EXPORT
                  </CSVLink>
                )}
              </button>
            </div>

            <div className="dashboard-sidebar table-wrapper max-h-[20rem] overflow-auto">
              <table
                className="w-full border border-[#F1F1F1] "
                style={{ borderCollapse: "collapse", borderSpacing: "0" }}
              >
                <thead className="bg-cyan-300 h-26 px-5 py-2 sticky top-[-1px] z-10">
                  <tr className="bg-cyan-300  ">
                    <th className="py-3 px-5 text-left">Withdrawal From</th>
                    <th className="py-3 px-5 text-left">Date</th>
                    <th className="py-3 px-5 text-left">Amount</th>
                    {/* <th className="py-3 px-5 text-left">Type</th> */}
                    <th className="py-3 px-5 text-left">Status</th>
                    <th className="py-3 px-5 text-left" colSpan={2}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pendingTrans
                    ?.filter((item) => item.user?.email.includes(searchValue))
                    ?.map((item) => <TableRow key={item?.id} row={item} />)}
                  {!isLoading &&
                    pendingTrans?.filter(
                      (item) => item.user?.email.includes(searchValue)
                    )?.length === 0 && (
                      <tr>
                        <td colSpan={5} className="text-center py-4">
                          No Results Found
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function TableRow({ row }: { row: UserTransaction }) {
  const [isReview, setReview] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { mutate: updateTransaction, isLoading } = useUpdateTransaction();

  // Determine the button class based on the isEdit state
  const buttonClass = isEdit
    ? "text-cyan-800 bg-cyan-300 border-cyan-800"
    : "text-gray-800 bg-[#F1F1F1]  border-[#8F92A1]";

  // Toggle detail view and edit mode
  const toggleDetail = () => {
    setReview(!isReview);
    setIsEdit(!isEdit);
  };

  return (
    <>
      <tr className={isReview ? "" : "bg-white"}>
        <td className="border-b border-grey-800/30 py-5 px-7">
          <p className="text-[#8F92A1] text-sm ">{row?.user?.email}</p>
        </td>
        <td className="border-b border-grey-800/30 rounded-l-lg py-5 px-2">
          <p className="text-[#8F92A1] text-sm ">
            {" "}
            {new Date(row?.createdAt)
              .toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .replace(/\//g, "-")}
          </p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <p className="text-[#8F92A1] text-sm  text-center flex items-center gap-1">
            {row?.type === "Withdraw Fiat" || row?.type === "Deposit Fiat" ? (
              <img src={XAFIcon} alt="icon" className="w-6" />
            ) : (
              <img src={row?.coin?.icon} alt="icon" className="w-6" />
            )}

            {row?.amount}
          </p>
        </td>
        {/* <td className="border-b border-grey-800/30 py-5  text-left px-5">
          <p className="text-[#8F92A1] text-sm ">{row?.type}</p>
        </td> */}
        <td className="border-b border-grey-800/30 py-5  text-left px-5">
          <p className="text-[#8F92A1] text-sm ">{row?.status}</p>
        </td>
        <td className="border-b border-grey-800/30 py-5 px-2">
          <button
            onClick={() => {
              toggleDetail();
            }}
            type="button"
            className={`text-xs font-semibold rounded-lg px-8 py-2.5 border ${buttonClass}`}
          >
            {isEdit ? "COLLAPSE" : "VIEW DETAIL"}
          </button>
        </td>
      </tr>
      {isReview && (
        <tr className="relative w-full">
          <td colSpan={6} className="p-0 border-t-0 w-full">
            <table className="px-6 pb-6 w-full">
              <tbody className="w-full">
                <tr className="flex ">
                  {row?.type !== "Withdraw Crypto" ? (
                    <td className="flex-1">
                      <strong className="text-xs">User bank account:</strong>
                      <span className="text-black-900 mt-3 text-xs leading-relaxed block">
                        {row?.accountNumber}
                      </span>
                      <span className="text-black-900 mt-3 text-xs leading-relaxed block">
                        {row?.accountType === "Orange"
                          ? row?.orangeName
                          : row?.accountType === "Mtn"
                          ? row?.mtnName
                          : row?.bankName}
                      </span>
                    </td>
                  ) : (
                    ""
                  )}
                  {row?.type === "Withdraw Crypto" ? (
                    <td className="flex-1">
                      <strong className="text-xs">Sender Wallet</strong>
                      <span className="text-black-900  mt-3 text-xs leading-relaxed flex items-center gap-1">
                        {row?.fromAddress}
                      </span>
                    </td>
                  ) : (
                    <td className="flex-1">
                      <strong className="text-xs">Amount</strong>
                      <span className="text-black-900  mt-3 text-xs leading-relaxed flex items-center gap-1">
                        {row?.type === "Withdraw Fiat" ||
                        row?.type === "Deposit Fiat" ? (
                          <img src={XAFIcon} alt="icon" className="w-6" />
                        ) : (
                          <img
                            src={row?.coin?.icon}
                            alt="icon"
                            className="w-6"
                          />
                        )}
                        {row?.amount}
                      </span>
                    </td>
                  )}
                  <td className="flex-1">
                    {row?.type !== "Withdraw Crypto" ? (
                      row?.accountType === "Bank" ? (
                        <strong className="text-xs">Receipt Proof</strong>
                      ) : (
                        <strong className="text-xs">Transaction ID</strong>
                      )
                    ) : (
                      <strong className="text-xs">
                        Reciver wallet address:
                      </strong>
                    )}

                    <span className="text-black-900  mt-3 text-xs leading-relaxed flex items-center gap-1">
                      {row?.type !== "Withdraw Crypto" ? (
                        row?.accountType === "Bank" ? (
                          <img
                            src={row?.bankTrxUrl}
                            alt="trxUrl"
                            className="w-32"
                          />
                        ) : row?.accountType === "Mtn" ? (
                          <strong className="text-xs">{row?.mtnTrxId}</strong>
                        ) : row?.accountType === "Orange" ? (
                          <strong className="text-xs">
                            {row?.orangeTrxId}
                          </strong>
                        ) : (
                          ""
                        )
                      ) : (
                        <strong className="text-xs">{row?.toAddress}</strong>
                      )}
                    </span>
                  </td>

                  <td className="flex-1" valign="middle">
                    <Link
                      to={`/users?search=${row?.user?.email}`}
                      className="text-black-900 text-x underline leading-relaxed font-semibold"
                    >
                      CHECK USER
                    </Link>
                  </td>
                  <td className="flex-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <button
                        type="button"
                        onClick={() =>
                          updateTransaction({
                            transactionId: row?.id,
                            status: "Rejected",
                            trxUrl: row?.trxUrl ? row?.trxUrl : "",
                          })
                        }
                        className="text-black text-xs bg-gray-400 font-medium rounded-lg px-8 py-2.5"
                        disabled={isLoading}
                      >
                        {/* {isLoading ? ( */}
                        {/* <div className="w-5 h-5 rounded-full animate-spin mx-auto border-2 border-solid border-cyan-800 border-t-transparent"></div> */}
                        {/* ) : ( */}
                        Reject
                        {/* )} */}
                      </button>
                      <button
                        onClick={() =>
                          updateTransaction({
                            transactionId: row?.id,
                            status: "Completed",
                            trxUrl: row?.trxUrl ? row?.trxUrl : "",
                          })
                        }
                        type="button"
                        className="bg-cyan-800 hover:text-cyan-800 hover:bg-cyan-300 text-white text-xs font-medium border border-cyan-800 rounded-lg px-8 py-2.5 transition"
                        disabled={isLoading}
                      >
                        {/* {isLoading ? ( */}
                        {/* <div className="w-5 h-5 rounded-full animate-spin mx-auto border-2 border-solid border-white border-t-transparent"></div> */}
                        {/* ) : ( */}
                        Approve
                        {/* )} */}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
