import React, { useEffect } from "react";
import LockImg from "assets/images/LockImg.png";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useVerifyEmail, useVerifyOtp } from "utils/api/auth.api";
import { type VerifyEmailForm } from "utils/types/user.type";
import { type SubmitHandler, useForm } from "react-hook-form";
import { Input } from "components/input";
import { limit } from "utils/helper";
// import WalletIcon from "assets/images/WalletIcon.svg";
import backIcon from "assets/icons/back.svg";
import Logo from "../../assets/icons/logo.svg";

export default function VerifyEmail() {
  const location = useLocation();
  const navigate = useNavigate();

  const { mutate: verifyEmail, isSuccess, isLoading } = useVerifyEmail();
  const {
    mutate: verifyOtp,
    isSuccess: otpSuccess,
    data: otpData,
    isLoading: otpLoading,
  } = useVerifyOtp();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<VerifyEmailForm>();

  const onSubmit: SubmitHandler<VerifyEmailForm> = (data) => {
    if (location?.state?.isForgot) {
      verifyOtp({ otp: data?.otp, email: location?.state?.email });
    } else verifyEmail({ otp: data?.otp, email: location?.state?.email });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Email verified successfully!");
      toast.error("Now wait for admin approval!");
      navigate("/login");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (otpSuccess) {
      localStorage.setItem("otp_token", otpData?.token?.access_token);
      navigate("/reset-password");
      toast.success("OTP verified successfully!");
    }
  }, [otpSuccess]);

  return (
    <section className="login-section  h-screen overflow-hidden">
      <div className="flex flex-col h-full ">
        <header
          style={{
            paddingTop: "30px",

            paddingLeft: "30px",

            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {" "}
          <img style={{ height: "50px" }} src={Logo} alt="" />{" "}
          {/* <span className="text-stone-700 text-x font-bold whitespace-nowrap">
            CRYPTO WALLET
          </span> */}
        </header>
        <div className="grid lg:grid-cols-12 h-screen gap-3">
          <div className="lg:col-span-6 flex items-center justify-center h-screen">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "40px" }}
              className="login-wrapper w-full max-w-md mx-auto p-2"
            >
              <Link
                style={{ display: "flex", gap: "14px", marginBottom: "0px" }}
                className=" text-black-800 text-[18px] font-[Poppins] font-bold mb-8 xl:mb-16"
                to="/forgot-password"
              >
                {" "}
                <img src={backIcon} alt="" /> Back
              </Link>
              <div>
                <h5 className=" text-black-800 text-[32px] font-[Poppins] font-bold mb-2">
                  {location?.state?.isForgot ? "Verify OTP" : "Verify Email"}
                </h5>
                <p
                  style={{ marginBottom: "0px" }}
                  className=" text-[#8F92A1] text-base mb-8 xl:mb-16"
                >
                  We have sent an email to your email{" "}
                  <strong className="text-cyan-800 text-x font-heavy whitespace-nowrap">
                    {location?.state?.email}
                  </strong>
                  <br />
                  account with a verification code
                </p>
              </div>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises  */}
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <Input
                    isInvalid={Boolean(errors?.otp)}
                    errorMessage={errors?.otp?.message}
                    label="Verification code"
                    type=""
                    placeholder=""
                    register={register}
                    name="otp"
                    rules={{ required: "Code is required" }}
                    inputProps={{
                      onKeyDown: (e) => limit(e),
                      onKeyUp: (e) => limit(e),
                    }}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-cyan-800  hover:text-cyan-800 hover:bg-cyan-300 text-white text-x font-medium border border-cyan-800 rounded-lg w-full p-3.5 transition"
                  disabled={isLoading || otpLoading}
                >
                  {isLoading || otpLoading ? (
                    <div
                      className="w-8 h-8 rounded-full animate-spin mx-auto
                      border-4 border-solid border-cyan-800 border-t-transparent"
                    ></div>
                  ) : (
                    "CONTINUE"
                  )}
                </button>
                <div className="text-center" style={{ padding: "0px" }}>
                  <Link
                    to="/login"
                    className="text-cyan-800 text-x font-heavy whitespace-nowrap"
                  >
                    I remember the password
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div className="lg:col-span-6 relative lg:overflow-hidden">
            <div className="login-bg h-full flex items-center justify-center">
              <img
                style={{ height: "680px" }}
                className="max-w-xs lg:max-w-none object-contain"
                src={LockImg}
                alt="title"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
